// React core
import React, { Component } from 'react'

// CSS imports
import '../stylesheets/components/viewports/MiniLoading.css'

/*
**  Mini loading spinner component
*/

class MiniLoading extends Component {

  // Main render
  render() {
    const status = this.props.trigger ? "spin" : "idle"
    const style = this.props.style ? this.props.style : {}
    const parentStyle = this.props.parentStyle ? this.props.parentStyle : {}
    return <div className={`flex-col flex-middle mini-wrapper ${status}`} style={parentStyle}>
      <div className={`mini-spinner flex-col flex-middle ${status}`} style={style}>-</div>
    </div>
  }
}

export default MiniLoading
