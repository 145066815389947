// React core
import React, { Component } from 'react'

// Local components
import SessionDetails from '../overlays/SessionDetails'
import MapTrip        from '../overlays/Map'
import RawDataHeaders from '../overlays/RawDataHeaders'

import Invite         from '../overlays/Invite'
import MiniLoading    from '../MiniLoading'
import Search         from './Search'
import Sensors        from './Sensors'


// Local functions
import { tl }                       from '../../helpers/locale'
import { authRequest, apiRequest }  from '../../helpers/http'
import { fullString, renderDate, renderDuration, segmentArray, exists } from '../../helpers/common'

// Local data
import Locale from '../../data/locale/Sessions.json'
import Errors from '../../data/locale/errors.json'

import { paginationSize } from '../../data/config'

// External imports
import { withAlert } from 'react-alert'

// CSS imports
import '../../stylesheets/components/viewports/Sessions.css'

// Requires
const download = require('downloadjs')
const rawIMU = `raw_imu_sessions`
const rawECG = `raw_ecg_sessions`
/*
**  Display user's sessions
*/

const ASC   = 1
const DESC  = -1

class Sessions extends Component {

  // Important class constants
  sessionKeys   = [ "label", "type", "created_at", "duration", "rider", "horse_name", "firmware_version", "app_version" ]
  allowSorting  = [ "created_at", "duration", "rider", "horse_name" ]

  checks = {

    // Horses checked
    horses: (session) => {
      return this.state.filtering.horses.length === 0
        || this.state.filtering.horses.includes(session.horse_id)
    },

    // Riders checked
    riders: (session) => {
      const name = `${session.user_id}`.trim()
      return this.state.filtering.riders.length === 0
        || this.state.filtering.riders.includes(name)
    }
  }

  // Class constructor
  constructor(props) {
    super(props)
    this.state = {
      loading:        false,

      horsesLoaded:   false,
      usersLoaded:   false,
      sensorsLoaded: false,

      disableCompare: false,
      loadDetails:    false,
      loadMap:        false,
      loadJson:       false,
      loadingSort:    null,
      index:          0,

      horses:         [],
      users:          [],
      sensors:        [],

      // Filtering infos
      searchOpen: false,
      filtering: {
        riders: [],
        horses: []
      },

      // Sorting infos
      sorting: {
        key: "created_at",
        direction: DESC
      },

      // Empty arrays
      selected:   [],
      dlProcessDetails:  [],
      dlProcessMotion:  [],
      dlProcessHR:  [],
      dlIMU:      [],
      dlECG:      [],
      dlGPX:      [],


      // View types
      currentView: "researcherView"
    }
  }

  fetchHorses(token, id) {
    return this.fetchData(token, `/users/${id}/horses`, {deleted: true})
      .then(data => {
        this.setState({
          horses: data,
          horsesLoaded: true,
        })
        return data
      })
      .catch(error => console.log("horse/user", error))
  }

  fetchUsers(token, uuid) {
    return this.fetchData(token, `/share/horse/${uuid}/user`)
      .catch(error => {
        console.log(error)
      })
  }

  fetchSensors(token, id) {
    return this.fetchData(token, `/users/${id}/sensors`, {all: true})
      .then(data => {
        this.setState({
          sensors: data,
          sensorsLoaded: true,
        })
        return data
      })
      .catch(error => console.log("sensors", error))
  }

  fetchData(token, endpoint, params = {}) {
    return apiRequest(token, endpoint, {}, params)
      .then(resp => {
        if (Math.floor(resp.status / 100) !== 2) {
          this.props.alert.show("no data", {type: "error"})
          return Promise.reject(resp.status)
        } else {
          return resp
        }
      })
      .then(response => {
        return response.json()
      })
    .catch(error => {
      console.log(error)
    });
  }

  componentDidMount(){
    var users = []
    this.setState(
      { loading: true }, () => {
      // Compute request
      authRequest(this)
        .then(token => this.fetchSensors(token, this.props.parent.state.profileID)
        .then(this.fetchHorses(token, this.props.parent.state.profileID)
        .then(data => {
          var uHorses = Array.from(new Set(data.map((item: any) => item.id)))
          Promise.all(uHorses.map(horse => {
            return this.fetchUsers(token, horse)
          }))
          .then(res => {
            res.map(user => {
              users = [...users, ...user]
              return users
            })
            this.setState({
              users: users,
            }, () => {
              this.setState({
                usersLoaded: true,
              })
            })
          })
        })))
    .catch(error => console.log(error))
    })
  }

  getUserName(uuid) {
    const usersMatching = this.state.users.filter(user=> user.id===uuid)
    const name = usersMatching.length > 0 ? usersMatching[0].first_name + " " + this.state.users.filter(user=> user.id===uuid)[0].last_name : uuid
    return name
  }

  getHorseName(uuid) {
    const horsesMatching = this.state.horses.filter(horse=> horse.id===uuid)
    const name = horsesMatching.length > 0 ? horsesMatching[0].name : uuid
    return name
  }

  isHorseDeleted(uuid) {
    const horsesMatching = this.state.horses.filter(horse=> horse.id===uuid)
    const isDeleted = horsesMatching.length > 0 ? (horsesMatching[0].deleted_at ? "deleted" : "") : ""
    return isDeleted
  }

  // Update filtering
  updateFiltering = (event) => {
    const key = event.currentTarget.dataset.key
    const value = event.currentTarget.value

    // Define new structure
    const newStruct = this.state.filtering[key].includes(value)
      ? this.state.filtering[key].filter(x => x !== value)
      : this.state.filtering[key].concat([value])

    // Update state
    this.setState({
      filtering: Object.assign(this.state.filtering, { [key]: newStruct })
    })
  }

  getMotionType = (motion) => {
    if (motion.hardware_revision === 1) {
      return "motion"
    }
    if (motion.hardware_revision === 11) {
      return "motion❤️"
    }
    if (motion.hardware_revision >= 100) {
      return "motion_saddle"
    }
    return "motion❔"
  }

  getSessionType = (session) => {
      if (exists(session.motion) && exists(session.trip)) {
        return this.getMotionType(session.motion) + "_trip"
      }
      if (exists(session.motion)) {
        return this.getMotionType(session.motion)
      }
      if (exists(session.trip)) {
        return "trip"
      }
      if (exists(session.care)) {
        return "healthcare"
      }
      return "activity"
    }

  // Main render
  render() {
    return !Array.isArray(this.props.parent.state.sessions) || this.props.parent.state.sessions.length === 0
      ? <div className="top-controls flex-col center-h">{tl(Locale.nosession, this.props.parent)}
        <button
          className="table-button pink admin-button"
          onClick={this.props.loadSearch}
        >{tl(Locale.back, this.props.parent)}
        </button>
        </div>
      : <div className="sessions-wrapper flex-col">
          <div className="flex-row view-selector">
            <img src={`/gui/${this.state.currentView}.png`} alt={this.state.currentView}/>
            <select onChange={this.updateView} value={this.state.currentView} className="view-changer">
              <option value="researcherView">{tl(Locale.tableView, this.props.parent)}</option>
              <option value="userView">{tl(Locale.prettyView, this.props.parent)}</option>
            </select>
          </div>
          {this[this.state.currentView]()}
        </div>
  }

  // Update pagination
  updatePagination = (event) => {
    this.setState({ index: parseInt(event.currentTarget.value, 10) })
  }

  // Render user view
  userView = () => {
    const sessions = !this.state.searchOpen
      ? this.props.parent.state.sessions
      : segmentArray((this.props.parent.state.sessions
        .map(group => group.filter(this.applyFilter))
        .reduce((acc, x) => acc.concat(x), [])
      ), paginationSize)

    // Determine index to use as fallback
    const index = sessions[this.state.index] ? this.state.index : sessions.length - 1

    // Return viewport
    return <div className="flex-col fp-sessions">

      <div className="flex-col flex-left">

        <div className="flex-row">

          {/* Filtering toggle */}
          <label className="table-button filtering-input flex-row flex-middle">
            {tl(Locale.filter, this.props.parent)}
            <input
              type="checkbox"
              checked={this.state.searchOpen}
              onChange={this.toggleFiltering}
            />
          </label>
        </div>

        {/* Filtering bar */}
        {
          ((this.state.horsesLoaded && this.state.usersLoaded) &&
            <Search
              open={this.state.searchOpen}
              filtering={this.state.filtering}
              updateFiltering={this.updateFiltering}
              parent={this.props.parent}
              horses={this.state.horses}
              users={this.state.users}
            />) || <MiniLoading trigger={true}/>
        }

      </div>

      {/* Pagination */}
      <hr/>
      <h2>{tl(Locale.page, this.props.parent)}</h2>
      <div className="flex-row pagination">
        {new Array(sessions.length)
          .fill(true)
          .map((_v, idx) => <p key={`pagin-${idx}`}>
            <button
              className={idx === index ? "selected" : ""}
              value={idx}
              onClick={this.updatePagination}
            >{idx+1}</button>
          </p>)}
      </div>
      {/* Session render */}
      <h2>{tl(Locale.sessions, this.props.parent)}</h2>
      <div className="sessions-group">
        <div>
          {sessions[index].map(session => <div key={`sess-${session.id}`}>
            {/* Session owner/horse infos */}
            <div className={`flex-row session-card ${this.getSessionType(session)}`}>
              <div className="flex-col session-infos">
                <h3 className="session-rider">{this.state.usersLoaded ? `${this.getUserName(session.user_id)}` : "Loading users..."}</h3>
                <p>{this.state.horsesLoaded ? `${this.getHorseName(session.horse_id)}` : "Loading horses..."}</p>
                {fullString(session.label) && <label>{session.label}</label>}
              </div>

              {/* Trip middle block */}
              {exists(session.trip) && <div className="session-middle flex-row center-v flex-right">
                <MiniLoading trigger={this.state.loadMap === session.id}/>

                {/* Fetch session details and display them */}
                <button
                  className="table-button grey"
                  value={session.trip.map_picture_url}
                  onClick={this.fetchMap}
                  disabled={this.state.loadMap}
                >{tl(Locale.map, this.props.parent)}</button>
              </div>}

              {/* Session middle block */}

              {exists(session.motion) && <div className="session-middle flex-row center-v flex-right">
                <MiniLoading trigger={this.state.loadDetails === session.id}/>
                {/* Fetch session details and display them */}
                <button
                  className="table-button green"
                  value={session.id}
                  onClick={this.fetchDetails}
                  disabled={this.state.loadDetails}
                >{tl(Locale.details, this.props.parent)}</button>
              </div>}
              {/* Session date/time infos */}
              <div className="flex-col center-v session-times">
                <strong>{renderDate(session.created_at)}</strong>
                {exists(session.motion) && renderDuration(session.motion.duration)}
                <span className={`${this.getSessionType(session)}`}>{`${this.getSessionType(session)}`}</span>
              </div>
            </div>
          </div>)}
        </div>
      </div>
    </div>
  }

  // Fetch session details
  fetchDetails = (event) => {
    const id = event.currentTarget.value

    // Start loading
    this.setState({ loadDetails: id }, () => {

      // Compute request
      authRequest(this)
        .then(token => this.fetchData(token, `/sessions/${id}/aggregation`))
        .then(data => {
          this.setState({ loadDetails: false }, () => {

            // Set parent state and update Overlay
            this.props.parent.setState({ sessionDetails: {
              data: data,
              metaInfos: this.props.parent.state.sessions
                .reduce((acc, x) => acc.concat(x), [])
                .filter(x => x.id === id)[0],
              horse_name: this.getHorseName(this.props.parent.state.sessions
                .reduce((acc, x) => acc.concat(x), [])
                .filter(x => x.id === id)[0].horse_id),
              user_name:this.getUserName(this.props.parent.state.sessions
                .reduce((acc, x) => acc.concat(x), [])
                .filter(x => x.id === id)[0].user_id)

            // Show overlay after state has been changed
            }}, () => { this.props.parent.showOverlay(SessionDetails) })
          })
        })

        // Compute error
        .catch(err => {
          console.log(err)
          this.props.alert.show(tl(Locale.failedDetails, this.props.parent), {type: "error"})
          this.setState({ loadDetails: false })
        })
    })
  }

  fetchMap = (event) => {
    // Start loading
    const mapUrl = event.currentTarget.value
    this.setState({ loadMap: mapUrl }, () => {

      this.setState({ loadMap: false }, () => {

        // Set parent state and update Overlay
        this.props.parent.setState({
          mapUrl: mapUrl
        }, () => { this.props.parent.showOverlay(MapTrip) })
      })
    })
  }

  fetchIMUJsonHeaders = (event) => {
    this.fetchJsonHeaders(rawIMU, event)
  }

  fetchECGJsonHeaders = (event) => {
    this.fetchJsonHeaders(rawECG, event)
  }

  fetchJsonHeaders = (endpointBase, event) => {
    const id = event.currentTarget.value
    this.setState({ loadJson: id }, () => {

    // Start loading
      authRequest(this)
        .then(token => this.fetchData(token, `/${endpointBase}/${id}/headers`))
        .then(data => {
          console.log("jsonHeaders", data)
          this.setState({ loadJson: false }, () => {

            // Set parent state and update Overlay
            this.props.parent.setState({ jsonHeaders: {
              data: data
            }}, () => { this.props.parent.showOverlay(RawDataHeaders) })
          })
        // Compute error
        })
        .catch(err => {
          console.log(err)
          this.props.alert.show(tl(Locale.failedDetails, this.props.parent), {type: "error"})
          this.setState({ loadJson: false })
        })
      })
  }

  // Render researcher view
  researcherView = () => {
    return [
      this.topControls(),
      <div className="table-wrapper" key="researcher-table">
        <table className="fp-table">

          {/* Table head */}
          <thead>
            <tr>

              {/* Select All header */}
              <th className="checkbox-col">
                <label>
                  <input
                    type="checkbox"
                    checked={this.state.selected.length === this.props.parent.state.sessions.reduce((acc, x) => acc.concat(x), []).length}
                    onChange={this.selectAll}
                  />
                </label>
              </th>

              {/* Render table headers */}
              {this.sessionKeys.map(this.renderHeader)}
              <th/>
              <th/>
              <th/>
            </tr>
          </thead>

          {/* Table body */}
          <tbody>
            {this.props.parent.state.sessions
              .reduce((acc, arr) => acc.concat(arr), [])
              .filter(this.applyFilter)
              .sort(this.sortRows)
              .map(session => <tr key={`srow-${session.id}`}>
                <td className="checkbox-col">
                  <label>
                    <input
                      type="checkbox"
                      value={session.id}
                      checked={this.state.selected.includes(session.id)}
                      onChange={this.updateSelection}
                    />
                  </label>
                </td>

                {/* Render columns */}
                {this.sessionKeys.map(k => this.renderColumn(session, k))}

                {/* Render download buttons */}
                <td>{this.smallDownloadButton(session, "dlProcessDetails", "Details", this.fetchDetails, Locale.smallDetails)}
                    {this.downloadButton(session, "dlProcessMotion", "green", this.downloadProcessedData, Locale.processedMotion)}

                    {this.downloadButton(session, "dlProcessHR", "red", this.downloadProcessedHR, Locale.processedHR)}
                </td>
                <td>{this.downloadButton(session, "dlIMU", "yellow", this.downloadRawData, Locale.rawdata)}
                    {this.smallDownloadButton(session, "dlIMU", "IMUheaders", this.fetchIMUJsonHeaders, Locale.jsonHeaders)}
                </td>
                <td>{this.downloadButton(session, "dlECG", "blue", this.downloadECG, Locale.ecgdata)}
                    {this.smallDownloadButton(session, "dlECG", "ECGheaders", this.fetchECGJsonHeaders, Locale.jsonHeaders)}
                </td>
                <td>{this.smallDownloadButton(session, "dlGPX", "Map", this.fetchMap, Locale.smallmap)}
                    {this.downloadButton(session, "dlGPX", "purple", this.downloadGPX, Locale.gpsdata)}
                </td>

              </tr>)}
          </tbody>
        </table>
      </div>
    ]
  }

  // Apply filter
  applyFilter = (session) => {

    // Validate if filtering is disabled OR if all checks are true
    return this.state.searchOpen === false
      || Object.values(this.checks).filter(f => f(session)).length === Object.values(this.checks).length
  }

  // Download raw data
  downloadRawData = (event) => {
    const id = event.currentTarget.value
    const name = event.currentTarget.name

    // Start loading status
    this.setState({ dlIMU: this.state.dlIMU.concat([id]) }, () => {

      // Compute request for raw data
      authRequest(this, "S-RDD")
        .then(token => apiRequest(token, `/raw_imu_sessions/${id}`))
        .then(resp => {
          if (Math.floor(resp.status / 100) !== 2) {
            this.props.alert.show(tl(Locale.nosession, this.props.parent), {type: "error"})
            return Promise.reject(resp.status)
          } else {
            return resp
          }
        })
        .then(resp => resp.text())
        .then(csv => download(csv, `imu_${name}.csv`, "application/csv"))
        .then(this.removeLoading("dlIMU", id))


        // Handle failures
        .catch(err => {
          console.log(err)
          this.removeLoading("dlIMU", id)
        })
    })
  }

  // Download processed data
  downloadProcessedData = (event) => {
    const name = event.currentTarget.name
    const id = event.currentTarget.value

    // Start loading status
    this.setState({ dlProcessMotion: this.state.dlProcessMotion.concat([id]) }, () => {

      // Compute request for processed data
      authRequest(this, "S-PDD")
        .then(token => apiRequest(token, `/sessions/${id}/csv/motion`))
        .then(resp => this.checkResponse(resp, "processed"))
        .then(csv => {
          this.props.alert.show(tl(Locale.download, this.props.parent), { type: "success" })
          return csv
        })
        .then(csv => download(csv, `${name}_motion.csv`, "application/csv"))
        .then(() => this.removeLoading("dlProcessMotion", id))

        // Handle failures
        .catch(() => this.removeLoading("dlProcessMotion", id))
    })
  }

  downloadProcessedHR = (event) => {
    const name = event.currentTarget.name
    const id = event.currentTarget.value

    // Start loading status
    this.setState({ dlProcessHR: this.state.dlProcessHR.concat([id]) }, () => {

      // Compute request for processed data
      authRequest(this, "S-PDD")
        .then(token => apiRequest(token, `/sessions/${id}/csv/heart_rate`))
        .then(resp => this.checkResponse(resp, "processed"))
        .then(csv => {
          this.props.alert.show(tl(Locale.download, this.props.parent), { type: "success" })
          return csv
        })
        .then(csv => download(csv, `${name}_hr.csv`, "application/csv"))
        .then(() => this.removeLoading("dlProcessHR", id))

        // Handle failures
        .catch(() => this.removeLoading("dlProcessHR", id))
    })
  }

  // Download ECG data
  downloadECG = (event) => {
    const id = event.currentTarget.value
    const name = event.currentTarget.name

    // Start loading status
    this.setState({ dlECG: this.state.dlECG.concat([id]) }, () => {

      // Compute request for ECG
      authRequest(this, "S-EDD")
        .then(token => apiRequest(token, `/raw_ecg_sessions/${id}`))
        .then(resp => {
          if (Math.floor(resp.status / 100) !== 2) {
            this.props.alert.show(tl(Locale.noECG, this.props.parent), {type: "error"})
            return Promise.reject(resp.status)
          } else {
            return resp
          }
        })
        .then(resp => resp.text())
        .then(csv => download(csv, `ecg_${name}.csv`, "application/csv"))
        .then(console.log)
        .then(this.removeLoading("dlECG", id))


        // Handle failures
        .catch(err => {
          console.log(err)
          this.removeLoading("dlECG", id)
        })
    })
  }

  downloadGPX = (event) => {
    const id = event.currentTarget.value
    const name = event.currentTarget.name

    // Start loading status
    this.setState({ dlGPX: this.state.dlGPX.concat([id]) }, () => {

      // Compute request for ECG
      authRequest(this, "S-GDD")
        .then(token => apiRequest(token, `/v2/activities/${id}/trip/gpx`))
        .then(resp => {
          if (Math.floor(resp.status / 100) !== 2) {
            this.props.alert.show(tl(Locale.noGPS, this.props.parent), {type: "error"})
            return Promise.reject(resp.status)
          } else {
            return resp
          }
        })
        .then(resp => resp.text())
        .then(gpx => download(gpx, `gps_${name}.gpx`, "application/gpx+xml"))
        .then(console.log)
        .then(this.removeLoading("dlGPX", id))


        // Handle failures
        .catch(err => {
          console.log(err)
          this.removeLoading("dlGPX", id)
        })
    })
  }

  // Check if file was recovered from API
  checkResponse = (resp, key) => {
    if (resp.status === 200) {
      return resp.text()
    } else {
      const msg = Errors[key] && Errors[key][resp.status]
        ? Errors[key][resp.status]
        : Errors.unknown

      // Show alert
      this.props.alert.show(tl(msg, this.props.parent), { type: "error" })
      return Promise.reject("Request failed")
    }
  }

  // Remove loading for Key/ID
  removeLoading = (key, id) => {
    this.setState({ [key]: this.state[key].filter(x => x !== id) })
  }

  buttonState(session, key) {
    switch (key) {
    case 'dlProcessMotion':
    case 'dlProcessDetails':
    case 'dlIMU':
      if (this.getSessionType(session).includes("motion")) {
        return false
      }
      return true
    case 'dlGPX':
      if (this.getSessionType(session).includes("trip")) {
        return false
      }
      return true
    case 'dlProcessHR':
    case 'dlECG':
      if ((this.getSessionType(session) === "motion") ||
        (this.getSessionType(session) === "motion_trip") ||
        (this.getSessionType(session) === "trip") ||
        (this.getSessionType(session) === "activity") ||
        (this.getSessionType(session) === "healthcare")) {
        return true
      }
      return false
    default:
      return false
    }
  }

  smallDownloadButton(session, key, name, listener, locale) {
    var value = session.id
    if ((key === 'dlGPX') && exists(session.trip)) {
      value = session.trip.map_picture_url
    }
    return this.state[key].includes(session.id)
      ? <MiniLoading trigger={true}/>
      : <button
          className={`table-button grey`}
          onClick={listener}
          name={name}
          value={value}
          disabled={this.buttonState(session, key)}
        >{tl(locale, this.props.parent)}
        </button>
  }

  // Render download button
  downloadButton(session, key, color, listener, locale) {
    const date = new Date(parseInt(session.created_at, 10) * 1000)
    const name = `${this.getHorseName(session.horse_id)}_${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}_${date.getHours()}h${date.getMinutes()}`
    return this.state[key].includes(session.id)
      ? <MiniLoading trigger={true}/>
      : <button
          className={`table-button ${color}`.trim()}
          onClick={listener}
          name={name}
          value={session.id}
          disabled={this.buttonState(session, key)}
        >{tl(locale, this.props.parent)}
        </button>
  }
  /*
  **  Render top controls
  */

  // Render top controls
  topControls = () => {
    return [<div className="top-controls flex-col center-h" key="researcher-topcontrols">

      {/* Back to search button */}
      {this.props.parent.state.userData.status >= 4 && <button
        className="table-button pink admin-button"
        onClick={this.props.loadSearch}
      >{tl(Locale.back, this.props.parent)}</button>}
      <br></br>
      {this.state.sensorsLoaded ? <Sensors sensors={this.state.sensors} parent={this.props.parent}/> : <MiniLoading trigger={true}/>}
      {/* Sessions controls */}
      <h2>{tl(Locale.sessions, this.props.parent)}</h2>
      <div className="flex-row">

        {/* Compare sessions */}
        <button
          className="table-button green"
          disabled={this.state.selected.length === 0 || this.state.disableCompare}
          onClick={this.downloadCompare}
        >{tl(Locale.compare, this.props.parent)}</button>
        <MiniLoading trigger={this.state.disableCompare}/>

        {/* Invite rider */}
        {this.canInvite() && <button
          className="table-button pink"
          disabled={this.state.disableInvite}
          onClick={() => this.props.parent.showOverlay(Invite)}
        >{tl(Locale.invite, this.props.parent)}</button>}

        {/* Toggle filtering */}
        <label className="table-button filtering-input flex-row flex-middle">
          {tl(Locale.filter, this.props.parent)}
          <input
            type="checkbox"
            checked={this.state.searchOpen}
            onChange={this.toggleFiltering}
          />
        </label>
      </div>

        {/* Filtering bar */}
        {
          (this.state.horsesLoaded && this.state.usersLoaded)
          ?  <Search
              open={this.state.searchOpen}
              filtering={this.state.filtering}
              updateFiltering={this.updateFiltering}
              parent={this.props.parent}
              horses={this.state.horses}
              users={this.state.users}
            /> 
          : <MiniLoading trigger={true}/>
        }
    </div>
  ]}

  updateView = (event) => {
    this.setState({
      currentView: event.currentTarget.value,
      index: 0
    })
  }

  // Check if user can invite
  canInvite() {
    return this.props.parent.state.userData.status === 3
      || (this.props.parent.state.userData.status === 4
        && this.props.parent.state.profileID === this.props.parent.state.userData.id
      )
  }

  // Toggle filtering
  toggleFiltering = (event) => {
    this.setState({
      searchOpen: !this.state.searchOpen,
      filtering: {
        horses: [],
        riders: []
      }
    })
  }

  // Download compare-session data
  downloadCompare = () => {
    this.setState({ disableCompare: true }, () => {
      authRequest(this, "S-DLC")
        .then(token => apiRequest(token, "/researchers/compare_sessions", {
          method: 'POST',
          body: JSON.stringify(this.state.selected)
        }))
        .then(res => res.text())
        .then(csv => download(csv, "compare_sessions.csv", "application/csv"))
        .then(() => this.setState({ disableCompare: false }))
        .then(() => this.props.alert.show(tl(Locale.download, this.props.parent), { type: "success" }))
    })
  }

  /*
  **  Table-related functions
  */

  // Select all sessions
  selectAll = () => {
    const flatten = this.props.parent.state.sessions.reduce((acc, x) => acc.concat(x), [])

    // If all sessions have already been selected, uncheck all
    if (this.state.selected.length === flatten.length) {
      this.setState({ selected: [] })

    // If some sessions are not selected, check all
    } else {
      this.setState({ selected: flatten.map(x => x.id) })
    }
  }

  // Update single session selection
  updateSelection = (event) => {
    const sessID = event.currentTarget.value

    // If session is already selected, uncheck
    if (this.state.selected.includes(sessID)) {
      this.setState({ selected: this.state.selected.filter(x => x !== sessID) })

    // If session isn't selected, check
    } else {
      this.setState({ selected: this.state.selected.concat([sessID]) })
    }
  }

  // Render table head
  renderHeader = (key) => {
    const direction = this.state.sorting.direction === ASC ? "asc" : "desc"
    return <th
      key={`th-${key}`}
      className={`th-sort ${this.state.sorting.key === key ? `active ${direction}` : ""}`.trim()}
    >
      <div className="flex-col flex-middle">
        {tl(Locale[key], this.props.parent)}
        {this.allowSorting.includes(key) && (this.state.loadingSort === key
          ? <MiniLoading
              trigger={true}
              style={{
                width: '20px',
                height: '20px'
              }}
              parentStyle={{
                marginLeft: '10px'
              }}/>
          : <button
              className="sort-button"
              value={key}
              onClick={this.updateSort}
              disabled={fullString(this.state.loadingSort)}
            ></button>
        )}
      </div>
    </th>
  }

  // Render single column
  renderColumn = (session, key) => {
    const k = `scol-${session.id}-${key}`

    // Map over render methods
    switch (key) {

      // Render computed date
      case "created_at":
        return <td key={k}>{renderDate(session[key])}</td>

      // Render computed duration
      case "duration":
        return <td key={k}>{(exists(session.motion) && renderDuration(session.motion.duration)) || (exists(session.trip) && renderDuration(session.trip.duration))}</td>

      // Concat first_name and last_name for rider
      case "rider":
        return <td key={k}>{this.state.usersLoaded ? `${this.getUserName(session.user_id)}` : <MiniLoading trigger={true}/>}</td>

      case "type":
        return <td key={k}>{this.getSessionType(session)}</td>

      case "firmware_version":
        return <td key={k}>{exists(session.motion) && session.motion.firmware_version}</td>

      case "app_version":
        return <td key={k}>{exists(session.motion) && session.motion.app_version}</td>

      case "horse_name":
        return <td className={this.isHorseDeleted(session.horse_id)} key={k}>{this.state.horsesLoaded ? `${this.getHorseName(session.horse_id)}` : <MiniLoading trigger={true}/>}</td>

      // Default render
      default:
        return <td key={k}>{session[key]}</td>
    }
  }

  // Update sorting method
  updateSort = (event) => {
    const key = event.currentTarget.value
    this.setState({ loadingSort: key }, () => {

      // Include tiny delay for animation purposes
      window.setTimeout(() => {
        this.setState({
          sorting: {
            key: key,
            direction: this.state.sorting.key === key ? this.state.sorting.direction * -1 : DESC
          }}, () => this.setState({ loadingSort: null }))
      }, 50)

    })
  }

  // Sort rows
  sortRows = (a, b) => {
    const access = (x) => this.state.sorting.key !== "rider"
      ? x[this.state.sorting.key]
      : `${x.first_name} ${x.last_name}`.trim()

    // Access data and sort them
    const v1 = access(a)
    const v2 = access(b)
    const res = v1 === v2
      ? (a.id > b.id ? 1 : -1)
      : (v1 > v2 ? 1 : -1)

    // Return computed result
    return res * this.state.sorting.direction
  }

}

export default withAlert(Sessions)
