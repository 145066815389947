// React core
import React, { Component } from 'react'

// CSS imports
import '../stylesheets/components/Overlay.css'

/*
**  Overlay template component
*/

class Overlay extends Component {

  // Main render
  render() {
    return <div className="flex-col flex-middle overlay-wrapper">
      <div className="flex-col flex-middle overlay-window">
        <this.props.component parent={this.props.parent}/>
      </div>
    </div>
  }
}

export default Overlay
