// React core
import React, { Component } from 'react'

// Local functions
import { fullString } from '../helpers/common'
import { tl }         from '../helpers/locale'

// Local data
import Locale from '../data/locale/LoadingSpinner.json'

// CSS imports
import '../stylesheets/components/LoadingSpinner.css'

/*
**  Loading spinner wrapper component
*/

class LoadingSpinner extends Component {

  // Render
  render() {

    // Determine rendered text
    const text = fullString(this.props.customText)
      ? this.props.customText
      : tl(Locale.loading, this.props.parent)

    // Return spinner
    return <div className="spinner-wrapper flex-row flex-middle">
      <div className="spinner-block">
        <div className="loading-spinner"/>
        <p>{text}</p>
      </div>
    </div>
  }

}

export default LoadingSpinner
