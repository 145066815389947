// React core
import React, { Component } from 'react'

// Local functions
import { renderDate, renderDuration, exists } from '../../helpers/common'
import { tl }                         from '../../helpers/locale'

// Local data
import Locale from '../../data/locale/SessionDetails.json'

// CSS imports
import '../../stylesheets/components/overlays/SessionDetails.css'

/*
**  Single session details component
*/

class SessionDetails extends Component {

  // Main render
  render() {
    const details = this.props.parent.state.sessionDetails

    const symmetryNote = details.data.symmetry
      ? `${Math.floor(details.data.symmetry * 10) / 10}/10`
      : "-"

    // Return viewport
    return <div className="session-details flex-col center-h">
      <button
        className="details-close flex-col flex-middle"
        onClick={this.props.parent.removeOverlay}
      >+</button>

      {/* Session date and duration */}
      <h2>
        {[
          tl(Locale.title, this.props.parent),
          renderDate(details.metaInfos.created_at)
        ].join(' ')}
      </h2>
      <p className="duration flex-col flex-middle">{renderDuration(details.metaInfos.motion.duration)}</p>

      {/* Session meta */}
      <hr/>
      <div className="flex-col">

        {/* Basic infos */}
        <div className="flex-row session-meta">
          <div>
            <label>{tl(Locale.rider, this.props.parent)}</label>
            <p>{`${details.user_name}`.trim()}</p>
          </div>
          <div>
            <label>{tl(Locale.horse, this.props.parent)}</label>
            <p>{details.horse_name}</p>
          </div>
        </div>

        {/* Label */}
        {details.metaInfos.label && <div className="session-label">{details.metaInfos.label}</div>}
      </div>

      <hr/>
      <div className="flex-row">

        {/* Global stats, col 1 */}
        <div className="global-stats flex-col center-h">
          {this.renderData("jumps", details.data.jump_count)}
          {this.renderData("straightTime", renderDuration(details.data.straight_time))}
        </div>

        {/* Global stats, col 2 */}
        <div className="global-stats flex-col center-h">
          {this.renderData("symmetry", symmetryNote)}
          {this.renderData("transitions", details.data.transition)}
        </div>
      </div>

      {/* Allure infos */}
      <hr/>
      <div className="allure-infos flex-row flex-wrap">
        {this.renderAllureData("walk")}
        {this.renderAllureData("trot")}
        {this.renderAllureData("canter")}
      </div>
    </div>
  }

  // Render allure data
  renderAllureData = (key) => {
    const details = this.props.parent.state.sessionDetails
    return <div className={`allure-wrapper flex-col center-h ${key}`}>
      <h3>{tl(Locale[key], this.props.parent)}</h3>
      {renderDuration(details.data.hand[key].left + details.data.hand[key].right)}
      <img src={`/gui/speed_${key}.png`} alt={key}/>
      <div className="flex-row center-v hand-work">
        <label>{tl(Locale.rightHand, this.props.parent)}</label>
        {renderDuration(details.data.hand[key].left)}
      </div>
      <div className="flex-row center-v hand-work">
        <label>{tl(Locale.leftHand, this.props.parent)}</label>
        {renderDuration(details.data.hand[key].right)}
      </div>
      <div className="flex-row center-v hand-work">
        <label>{tl(Locale.elevation, this.props.parent)}</label>
        <span>{exists(details.data.elevation[key]) && details.data.elevation[key].toFixed(1)}cm</span>
      </div>
      <div className="flex-row center-v hand-work">
        <label>{tl(Locale.stride_frequency, this.props.parent)}</label>
        <span>{exists(details.data.stride_frequency[key]) && details.data.stride_frequency[key].toFixed(1)}{this.renderUnit("stride")}</span>
      </div>
      <div className="flex-row center-v hand-work">
        <label>{tl(Locale.stride_regularity, this.props.parent)}</label>
        <span>{exists(details.data.stride_regularity[key]) && details.data.stride_regularity[key].toFixed(1)}/10</span>
      </div>
      <div className="flex-row center-v hand-work">
        <label>{tl(Locale.heart_rate, this.props.parent)}</label>
        <span>{exists(details.data.heart_rate[key]) && details.data.heart_rate[key].toFixed(1)}bpm</span>
      </div>

    </div>
  }

  // Render measuring unit
  renderUnit = (unit) => {
    return <a href="#" className="unit-wrapper">
      {tl(Locale[unit].notation, this.props.parent)}
      <div>{tl(Locale[unit].full, this.props.parent)}</div>
    </a>
  }

  // Render single data
  renderData = (key, data) => {
    return <div key={`metric-${key}`} className="flex-col flex-middle">
      <h3>{tl(Locale[key], this.props.parent)}</h3>
      <div className={`data-wrapper flex-col flex-middle ${key}`}>{data ? data : <span>{data}</span>}</div>
    </div>
  }
}

export default SessionDetails
