// React core
import React, { Component } from 'react'

class MapTrip extends Component {

  // Main render
  render() {
    const mapUrl = this.props.parent.state.mapUrl
    return <div className="session-map flex-col center-h">
          <button
        className="details-close flex-col flex-middle"
        onClick={this.props.parent.removeOverlay}
      >+</button>
      <img src={mapUrl} alt="openstreetmap"/>
    </div>
  }
}
export default MapTrip
