// React core
import React, { Component } from 'react'

// Local functions
import { tl } from '../../helpers/locale'

// Local data
import Locale from '../../data/locale/Search.json'

// CSS imports
import '../../stylesheets/components/viewports/Search.css'

/*
**  Search bar for Sessions component
*/

class Search extends Component {
  // Class constructor
  constructor(props) {
    super(props)
    const sessions = props.parent.state.sessions.reduce((acc, x) => acc.concat(x), [])
    const horseBasis = props.horses.filter(h => sessions.some(sess => sess.horse_id === h.id))
    const userBasis = props.users.filter(u => sessions.some(sess => sess.user_id === u.id))

    this.state = {
      // Riders and horses lists
      riders: userBasis.reduce((acc, s) => {
        const name = `${s.first_name} ${s.last_name}`.trim()
        return (acc.some(e => e.id === s.id)) ? acc : acc.concat([{name: name, id: s.id}])
      }, []),
      horses: horseBasis.reduce((acc, s) => {
        return (acc.some(e => e.id === s.id)) ? acc : acc.concat([{name: s.name, id: s.id}])
      }, []),
      // Initialize query data
      ridersFilter: "",
      horsesFilter: ""
    }
  }

  // Main render
  render() {
    return <div className={`filtering-wrapper flex-col ${this.props.open ? "open" : "close"}`}>
      <div className="flex-row">
        {this.renderFilter("riders")}
        {this.renderFilter("horses")}
      </div>
    </div>
  }

  // Render filter
  renderFilter(key) {
    const name = `${key}Filter`

    // Render part
    return <div className="filter flex-col">
      <label className="flex-col">
        {tl(Locale[key], this.props.parent)}
        <input
          type="text"
          placeholder={tl(Locale.search, this.props.parent)}
          name={name}
          value={this.state[name]}
          onChange={this.updateQuery}
        />
      </label>
      <div className="flex-row flex-wrap">
        {this.state[key].filter(
            data => data.name.toLowerCase().includes(
              this.state[name].trim().toLowerCase()
            )
          ).map(data => {
          const checked = this.props.filtering[key].some(e => e === data.id)
          return <label key={`q-${key}-${data.name}`} className={`flex-row ${checked ? "checked" : ""}`}>
            {data.name}
            <input
              type="checkbox"
              data-key={key}
              value={data.id}
              checked={checked}
              onChange={this.props.updateFiltering}
            />
          </label>})
        }
      </div>
    </div>
  }

  // Update query
  updateQuery = (event) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value })
  }
}

export default Search
