// React core
import React, { Component } from 'react'

// Local data
import { version, envType } from '../../../package.json'
import { languages } from '../../data/config'
import CL from '../../data/locale/common.json'

// CSS imports
import '../../stylesheets/components/viewports/Footer.css'

/*
**  Common app footer
*/

class Footer extends Component {

  // Main render
  render() {
    const env = envType.substr(0, 3).toUpperCase()
    return <footer className="falcon-footer flex-row center-v">
      <img src={`/flags/${this.props.parent.state.lang}.png`} alt={`language-${this.props.parent.state.lang}`}/>
      <select
        value={this.props.parent.state.lang}
        onChange={this.switchLang}
      >
        {languages.map(l => <option key={`ln-${l}`} value={l}>{CL[l]}</option>)}
      </select>
      <p><strong>{`v${version}-${env}`}</strong>{` «${this.vname()}»`}</p>
    </footer>
  }

  // Switch language
  switchLang = (event) => {
    this.props.parent.switchLang(event.currentTarget.value)
  }

  // Generate version name
  vname = () => {
    const portions = version.split('.')

    // Major version
    return [{
      "0": "Careful",
      "1": "Brave",
      "2": "Hotheaded",
      "3": "Incredible",
      "4": "Legendary"

    // Minor version
    }[portions[0]], {
      "0":  "German",
      "1":  "Pristine",
      "2":  "Genuine",
      "3":  "New",
      "4":  "Fabulous",
      "5":  "Prestigious",
      "6":  "Angry",
      "7":  "Golden",
      "8":  "Rough"

    // Revision
    }[portions[1]], {
      "0":  "Horse",
      "1":  "Orange",
      "2":  "Hypaspist",
      "3":  "Banana",
      "4":  "Moustache",
      "5":  "Spartan",
      "6":  "Broom",
      "7":  "Artist",
      "8":  "Swordfish",
      "9":  "Rider",
      "10": "Shepherd",
      "11": "Sentinel"

    }[portions[2]]].join(' ')
  }

}

export default Footer
