// React core
import React, { Component } from 'react'
import { withAlert } from 'react-alert'

import RawDataSwitch from '../RawDataSwitch'

import { authRequest, apiRequest }  from '../../helpers/http'
import { exists } from '../../helpers/common'
import '../../stylesheets/base/index.css'


const PCO = require('promise-composer')

class Sensors extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
  }
  this.blackListSensor = this.blackListSensor.bind(this)
}

  blackListSensor = (check, id) => {
    this.setState({sensorsLoaded: false}, () => {
      authRequest(this)
        .then(token => apiRequest(token, `/sensors/${id}/blacklist`, {
          method: "POST",
          body: JSON.stringify({blacklist: check})
        }))
        .then(resp => Promise.resolve(resp).then(x => PCO.assert(x, (x) => x.status === 204)))
        .then(this.setState({sensorsLoaded: true}))
        .then(this.props.alert.show("success", {type: "success"}))
      .catch(error => {
        this.props.alert.show("error", {type: "error"})
        console.log(error)
      });

    })
  }

  render() {
    const sensors = this.props.sensors

    return <div className="grid grid-cols-6 gap-4 center" key="sensor-table">
    {sensors
      .map(sensor =>
        <div className="oneSensor" key={`srow-${sensor.id}`}>
           <b>{sensor.name}</b>
           <br></br>{sensor.serial_id}
           <br></br>{sensor.hardware_revision}:
           {sensor.bootloader_revision}/{sensor.firmware_version}
          <br></br><RawDataSwitch
          checked={exists(sensor.blacklisted_at)}
          id={sensor.id}
          onChange={this.blackListSensor}/></div>
    )}
    </div>
  }

}

export default withAlert(Sensors)