// React core
import React, { Component } from 'react'

// Local components
import MiniLoading from '../MiniLoading'

// Local functions
import { tl }                       from '../../helpers/locale'
import { fullString }               from '../../helpers/common'
import { authRequest, apiRequest }  from '../../helpers/http'

// Local data
import Locale from '../../data/locale/Invite.json'

// External imports
import { withAlert } from 'react-alert'

// Requires
const PCO = require('promise-composer')

/*
**  Rider invitation component
*/

class Invite extends Component {

  // Class constructor
  constructor(props) {
    super(props)
    this.state = {
      userMail: "",
      loading: false
    }
  }

  // Send invite to rider
  sendInvite = (event) => {
    event.preventDefault()

    // Start loading
    this.setState({ loading: true }, () => {

      // Compute request
      authRequest(this)
        .then(token => apiRequest(token, "/researchers/send_request", {method: "POST"}, {email: this.state.userMail.trim()}))
        .then(resp => Promise.resolve(resp).then(x => PCO.assert(x, (x) => Math.floor(x.status / 100) === 2)))
        .then(resp => {
          this.setState({ loading: false })
          this.props.alert.show(tl(Locale.success, this.props.parent), {type: "success"})
          this.props.parent.removeOverlay()
        })

        // Handle errors
        .catch(err => {
          this.setState({ loading: false })
          this.props.alert.show(tl(Locale.failed, this.props.parent), {type: "error"})
        })
    })
  }

  // Update mail
  updateMail = (event) => {
    this.setState({ userMail: event.currentTarget.value })
  }

  // Main render
  render() {
    return <form className="flex-col center-h" onSubmit={this.sendInvite}>
      <h2>{tl(Locale.title, this.props.parent)}</h2>
      <p className="subtitle">{tl(Locale.subtitle, this.props.parent)}</p>
      <input
        type="text"
        onChange={this.updateMail}
        value={this.state.userMail}
        placeholder={tl(Locale.email, this.props.parent)}
        style={{width: "100%"}}
      />

      {/* Bottom controls */}
      <div className="flex-row center-v">
        <input
          type="submit"
          className="table-button orange"
          value={tl(Locale.yes, this.props.parent)}
          disabled={this.state.loading || !fullString(this.state.userMail)}
        />
        <MiniLoading trigger={this.state.loading}/>
        <button
          type="button"
          className="table-button orange"
          onClick={this.props.parent.removeOverlay}
        >{tl(Locale.no, this.props.parent)}</button>
      </div>
    </form>
  }
}

export default withAlert(Invite)
