// React core
import React, { Component } from 'react'

// Local components
import Login from './Login'
import Rider from './Rider'

/*
**  Main container component
*/

class Container extends Component {

  // Main render
  render() {
    return <main className="falcon-container flex-col">
      {this.props.parent.state.user
        ? <Rider parent={this.props.parent}/>
        : <Login parent={this.props.parent}/>
      }
    </main>
  }

}

export default Container
