// Local functions
import { fullString } from './common'

/*
**  URL function helpers
*/

// Get QueryString param from current page URL
export function qsParam(name) {
  return new URL(window.location.href).searchParams.get(name)
}

// Rewrite URL excluding some URL params
export function purgeQS(exclude) {
  const url = new URL(window.location.href)

  // Compose params and filter them
  const params = window.location.search.split('&')
    .map(x => x.replace(/\?/g, ""))
    .filter(x => !exclude.includes(x.split('=')[0]))
    .filter(fullString)

  // Assemble QS and remove excluded params
  const qs = params.length > 0 ? `?${params.join('&')}` : ""
  return Promise.resolve(window.history.replaceState({}, document.title, `${url.pathname}${qs}`))
}
