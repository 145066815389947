import React, { Component } from "react";
import Switch from "react-switch";

class RawDataSwitch extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      checked: this.props.checked,
      id: this.props.id,
      loading: false,
    }
    this.handleChange = this.handleChange.bind(this);
  }


  handleChange = (checked) => {
    try {
      this.props.onChange(checked, this.state.id)
      this.setState({checked:!this.state.checked})
    } catch(error) {
      console.log(error)
    }

  }


  render() {
    return (
      <label>
        <Switch onChange={this.handleChange} checked={this.state.checked} />
      </label>
    );
  }
}

export default RawDataSwitch
