// React core
import React, { Component } from 'react'

// Local components
import ErrorWrapper   from './ErrorWrapper'
import LoadingSpinner from './LoadingSpinner'
import Overlay        from './Overlay'
import Header         from './viewports/Header'
import Container      from './viewports/Container'
import Footer         from './viewports/Footer'

// Local functions
import { exists, fullString }       from '../helpers/common'
import { qsParam, purgeQS }         from '../helpers/url'
import { handle }                   from '../helpers/errors'
import { tl }                       from '../helpers/locale'

// Local data
import Errors from '../data/locale/errors.json'
import Locale from '../data/locale/App.json'

import { defaultLang, languages }     from '../data/config'
import { auth, abort, refreshAbort }  from '../data/config.js'

// External imports
import { withAlert } from 'react-alert'

// Requires
const PCO = require('promise-composer')

/*
**  Application entrypoint component
*/

class App extends Component {

  // Constructor
  constructor(props) {
    super(props)
    this.state = {
      loading: true,

      // Data inits
      user:     null,
      userData: null,
      sessions: null,
      acceptID: null,
      overlay:  null,
      lang:     defaultLang
    }
  }

  /*
  **  Component will mount, initialize language code and accept-id handling
  */

  componentWillMount() {

    // Fetch stored languages
    const lang = qsParam('lang')
    const localLang = localStorage.getItem('app-lang')

    // Determine used language code
    const langCode = exists(lang) && languages.includes(lang)
      ? lang
      : (exists(localLang) && languages.includes(localLang)
        ? localLang
        : defaultLang
      )

    // Update state
    this.setState({
      acceptID: qsParam('accept-id'),
      lang: langCode

    // Callback to finish loading
    }, () => {
      purgeQS(['accept-id'])
        .then(this.saveLang)
        .then(() => auth().onAuthStateChanged(this.login))
        .then(this.checkAcceptID)

        // Handle (eventual) error
        .catch(err => handle(this, Errors.unknown, "1-MTN"))
    })
  }

  // Check AcceptID
  checkAcceptID = () => {
    if (fullString(this.state.acceptID)) {
      this.props.alert.show(tl(Locale.invite, this), { timeout: 10000 })
      this.logout()
    }
  }

  // Login process
  login = (user) => {
    Promise.resolve(user)
      .then(PCO.exists)
      .then(u => this.setState({
        user: u,
        loading: false
      }))
      .catch(() => this.setState({ loading: false }))
  }

  // Logout process
  logout = (callback = () => null) => {
    abort.abort()
    auth().signOut()
      .then(() => this.setState({ user: null }, callback))
      .then(() => window.setTimeout(refreshAbort, 1000))
  }

  /*
  **  Simple update functions
  */

  // Switch language
  switchLang = (lang) => {
    this.setState({ lang: lang }, this.saveLang)
  }

  // Save language
  saveLang = () => {
    localStorage.setItem('app-lang', this.state.lang)
  }

  // Toggle loading
  toggleLoading = (val = !this.state.loading) => {
    this.setState({ loading: val })
  }

  // Main render
  render() {
    return fullString(this.state.lang) && this.state.loading
      ? <LoadingSpinner parent={this}/>
      : ( this.state.error
        ? <ErrorWrapper msg={this.state.error}/>
        : <div className="flex-col app-wrapper">
            {this.state.overlay && <Overlay
              parent={this}
              component={this.state.overlay.component}
              listener={this.state.overlay.listener}
            />}
            <Header parent={this}/>
            <Container parent={this}/>
            <Footer parent={this}/>
          </div>
      )
  }

  // Remove overlay
  removeOverlay = () => {
    this.setState({ overlay: null })
  }

  // Show overlay
  showOverlay = (component, listener = () => null) => {
    this.setState({ overlay: {
      component:  component,
      listener:   listener
    }})
  }
}

export default withAlert(App)
