// React core
import React, { Component } from 'react'

// Local components
import Admin          from './Admin'
import LoadingSpinner from '../LoadingSpinner'
import Sessions       from './Sessions'

// Local functions
import { tl }                       from '../../helpers/locale'
import { segmentArray }             from '../../helpers/common'
import { authRequest, apiRequest }  from '../../helpers/http'

// Local data
import Errors from '../../data/locale/errors.json'

import { paginationSize } from '../../data/config'

// External functions
import { withAlert } from 'react-alert'

const PCO = require('promise-composer')

/*
**  Rider profile component
*/

class Rider extends Component {

  // Class constructor
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      sessions: null,
      viewport: null
    }
  }

  // Component will mount
  componentWillMount() {
    /* DEBUG
    const mock = {currentTarget: {value: "64dbd293-446a-11e7-af41-0242ac110002"}}
    const mockUser = {status: 1, first_name: "debug", last_name: "hero"}
    this.props.parent.setState({ userData: mockUser}, () => this.checkProfile(mock))

    return
    /**/

    authRequest(this, "R-MTN")
      .then(token => apiRequest(token, "/users"))
      .then(resp => resp.json())
      .then(data => this.props.parent.setState({ userData: data }, this.determineAdmin))
  }

  // Determine which viewport to show if user is admin or not
  determineAdmin = () => {
    Promise.resolve(this.props.parent.state.userData.status)
      .then(x => PCO.assert(x, (y) => y >= 4))
      .then(() => this.setState({
        loading: false,
        viewport: Admin
      }))
      .catch(this.determineResearcher)
  }

  determineResearcher = () => {
    Promise.resolve(this.props.parent.state.userData.status)
      .then(x => PCO.assert(x, (y) => y === 3))
      // .then(() => this.setState({
      //   loading: false,
      //   viewport: Sessions
      // }))
      .then(() => this.fetchResearchSessions())
      .catch(this.fetchSessions)
  }

  // Go back to admin search
  loadSearch = () => {
    this.setState({ loading: true }, this.determineAdmin)
  }

  fetchData(token, endpoint, params = {}) {
    return apiRequest(token, endpoint, {}, params)
      .then(resp => {
        if (Math.floor(resp.status / 100) !== 2) {
          this.props.alert.show("no sessions", {type: "error"})
          return Promise.reject(resp.status)
        } else {
          return resp
        }
      })
      .then(response => {
        return response.json()
      })
    .catch(error => {
      console.log(error)
    });
  }

  fetchActivitiesAndHealthcares = (token, urlActivity, urlHealthcare) => {
    return Promise.all([
      this.fetchData(token, urlActivity, {show_deleted_horse: true}),
      this.fetchData(token, urlHealthcare, {show_deleted_horse: true})
    ])
  }

// Check user profile
  checkProfile = (event) => {
    const id = event.currentTarget.value

    // Start loading
    this.setState({ loading: true }, () => {
    var sessions = []
      // Compute request
      authRequest(this, "R-CUS")
        .then(token => this.fetchActivitiesAndHealthcares(token, `/v2/users/${id}/activities`, `/v2/users/${id}/healthcares`))
        .then(res => {
          res.map(res => {
            sessions = [...sessions, ...res]
            return sessions
          })
          this.props.parent.setState({
            sessions:   segmentArray(sessions.sort(this.sortCreation), paginationSize),
            profileID:  id
          })
        })
        .then(sessions => this.setState({
          loading: false,
          viewport: Sessions
        }))
        .catch(err => {
          console.log(err)
          this.props.alert.show(tl(Errors.unknown, this.props.parent), {type: "error"})
          this.setState({ loading: false })
        })
    })
  }

  // Sort by "created_at" property
  sortCreation = (a, b) => {
    return a.created_at === b.created_at
      ? (a.id < b.id ? 1 : -1)
      : (a.created_at < b.created_at ? 1 : -1)
  }

  // Fetch sessions
  fetchSessions = () => {
    var sessions = []

    authRequest(this, "R-SES")
    .then(token => this.fetchActivitiesAndHealthcares(token, "/v2/activities", "/healthcares"))
    .then(res => {
      res.map(res => {
        sessions = [...sessions, ...res]
        return sessions
      })
      this.props.parent.setState({
        sessions: segmentArray(sessions.sort(this.sortCreation), paginationSize),
        profileID:  this.props.parent.state.userData.id
      })
    })
    .then(() => this.setState({
      loading: false,
      viewport: Sessions
    }))
  }

  fetchResearchSessions = () => {
    console.log("fetch research")
    var sessions = []
    authRequest(this, "R-SER")
    .then(token => this.fetchActivitiesAndHealthcares(token, "/researchers/activities", "/researchers/healthcares"))
    .then(res => {
      res.map(res => {
        sessions = [...sessions, ...res]
        return sessions
      })
      this.props.parent.setState({
        sessions: segmentArray(sessions.sort(this.sortCreation), paginationSize),
        profileID:  this.props.parent.state.userData.id
      })
    })
    .then(() => this.setState({
      loading: false,
      viewport: Sessions
    }))
  }


  // Main render
  render() {
    return this.state.loading || !this.state.viewport
      ? <LoadingSpinner parent={this.props.parent}/>
      : <this.state.viewport
          parent={this.props.parent}
          loadSearch={this.loadSearch}
          checkProfile={this.checkProfile}
        />
  }
}

export default withAlert(Rider)
