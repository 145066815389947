// React core
import React, { Component } from 'react'

// Local components
import LoadingSpinner from '../LoadingSpinner'

// Local functions
import { tl }                       from '../../helpers/locale'
import { fullString }               from '../../helpers/common'
import { authRequest, apiRequest }  from '../../helpers/http'

// Local data
import Locale   from '../../data/locale/Login.json'
import { auth, providers } from '../../data/config.js'

// External imports
import { withAlert } from 'react-alert'

// CSS imports
import '../../stylesheets/components/viewports/Login.css'

/*
**  Login viewport component
*/

class Login extends Component {

  // Class constructor
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      email: "",
      password: ""
    }
  }

  // Main render
  render() {
    const loginText = tl(Locale.login, this.props.parent)
    return <div className="login-wrapper flex-col flex-middle">
      {this.state.loading
        ? <LoadingSpinner parent={this.props.parent} customText={loginText}/>
        : <div><form onSubmit={this.submitLogin} className="flex-col center-h">
            <input
              type="text"
              name="email"
              onChange={this.changeInput}
              value={this.state.email}
              placeholder={tl(Locale.email, this.props.parent)}
            />
            <input
              type="password"
              name="password"
              onChange={this.changeInput}
              value={this.state.password}
              placeholder={tl(Locale.password, this.props.parent)}
            />
            <input
              type="submit"
              className="button-orange"
              value={loginText}
            />
          </form>
          <br />
          <form onSubmit={this.submitFBLogin} className="flex-col center-h">
            <input
              type="submit"
              className="button-blue"
              value="Login with Facebook"
            />
          </form>
          <form onSubmit={this.submitAppleLogin} className="flex-col center-h">
            <input
              type="submit"
              className="apple-sign-in"
              value="🍏 Sign In with Apple"
            />
          </form>

        </div>
      }
    </div>
  }

  // Change input
  changeInput = (event) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value })
  }

  submitAppleLogin = (event) => {
    event.preventDefault()
    this.setState({ loading: true }, () => {

      // Auth user and check for callback
      auth().signInWithPopup(providers.apple)

        // User authentication succeeds
        .then(() => this.setState({ loading: false }, () => {
          this.props.alert.show(tl(Locale.welcome, this.props.parent), { type: "success" })
        }))
        .then(this.processAcceptID)

        // Process error
        .catch(err => {
          console.log(err)
          this.props.alert.show(err.message, { type: "error" })
          this.setState({ loading: false })
        })
    })
  }

  submitFBLogin = (event) => {
    event.preventDefault()
    this.setState({ loading: true }, () => {

      // Auth user and check for callback
      auth().signInWithPopup(providers.facebook)

        // User authentication succeeds
        .then(() => this.setState({ loading: false }, () => {
          this.props.alert.show(tl(Locale.welcome, this.props.parent), { type: "success" })
        }))
        .then(this.processAcceptID)

        // Process error
        .catch(err => {
          console.log(err)
          this.props.alert.show(err.message, { type: "error" })
          this.setState({ loading: false })
        })
    })
  }


  // Submit login
  submitLogin = (event) => {
    event.preventDefault()
    this.setState({ loading: true }, () => {

      // Auth user and check for callback
      auth().signInWithEmailAndPassword(this.state.email, this.state.password)

        // User authentication succeeds
        .then(() => this.setState({ loading: false }, () => {
          this.props.alert.show(tl(Locale.welcome, this.props.parent), { type: "success" })
        }))
        .then(this.processAcceptID)

        // Process error
        .catch(err => {
          console.log(err)
          this.props.alert.show(err.message, { type: "error" })
          this.setState({ loading: false })
        })
    })
  }

  // Check acceptance
  processAcceptID = () => {
    const id = this.props.parent.state.acceptID
    if (fullString(id)) {
      authRequest(this, "1-PAI")
        .then(token => apiRequest(token, `/researchers/accept_request/${id}`), {method: 'POST'})
        .then(() => {
          this.props.alert.show(tl(Locale.accepted, this.props.parent), {type: "success", timeout: 10000})
        })
        .catch(() => {
          this.props.alert.show(tl(Locale.failed, this.props.parent), {type: "error"})
        })
    }
  }
}

export default withAlert(Login)
