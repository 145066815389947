import React, { Component } from 'react'

import JSONPretty from 'react-json-pretty';

class RawDataHeaders extends Component {

  // Main render
  render() {
    const jsonHeader = this.props.parent.state.jsonHeaders.data;
    const JSONPrettyMon = require('react-json-pretty/dist/monikai');

    return (
      <div className="session-headers flex-col center-h">
        <button
          className="details-close flex-col flex-middle"
          onClick={this.props.parent.removeOverlay}
        >+</button>
        <JSONPretty
          id="json-pretty" data={JSON.stringify(jsonHeader)} theme={JSONPrettyMon}>
        </JSONPretty>
      </div>
    );
	}
}

export default RawDataHeaders
