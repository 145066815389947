// React core
import React, { Component } from 'react'

// CSS imports
import '../stylesheets/components/ErrorWrapper.css'

/*
**  Error display wrapper component
*/

class ErrorWrapper extends Component {

  // Render
  render() {
    return <div className="error-wrapper">
      {this.props.msg}
    </div>
  }

}

export default ErrorWrapper
