// React core
import React from 'react'

/*
**  Common utility helpers
*/

// Element isn't null or undefined
export function exists(x) { return x !== undefined && x !== null }

// Element is non-empty string
export function fullString(x) { return typeof x === "string" && x.trim() !== "" }

// Segment array into multiple arrays
export function segmentArray(arr, len) {

  // Reduce array to segments
  return arr.reduce((acc, x) => {
    const lastIDX = acc.length - 1

    // If segmentation has just started, simply concat
    if (lastIDX < 0 || (Array.isArray(acc[lastIDX]) && acc[lastIDX].length >= len)) {
      return acc.concat([[x]])

    // If segmentation can go on, continue last segment
    } else {
      return acc.slice(0, -1).concat([acc[lastIDX].concat([x])])
    }
  }, [])
}

// Render date
export function renderDate(date) {
  return new Date(date * 1000).toLocaleString()
}

// Render duration
export function renderDuration(duration) {
  const hours = duration < 3600 ? "" : `${Math.floor(duration / 3600)}h`
  const minutes = duration < 60 ? "" : `${Math.floor((duration % 3600) / 60)}m`
  const seconds = `${Math.floor(duration % 60)}s`
  return <span className={`duration-display`}>{hours}{minutes}{seconds}</span>
}
