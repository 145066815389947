// React core
import React, { Component } from 'react'

// Local functions
import { tl }                       from '../../helpers/locale'
import { authRequest, apiRequest }  from '../../helpers/http'
import Locale from '../../data/locale/AppReports.json'
import { renderDate } from '../../helpers/common'
import { withAlert } from 'react-alert'

const download = require('downloadjs')
const PCO = require('promise-composer')


class AppReports extends Component {
  smallDownloadButton(userId, timestamp, listener) {
    const value = `/users/${userId}/app_reports/${timestamp}`
    return <button
          className={`table-button grey`}
          onClick={listener}
          name={`Download`}
          value={value}
        >{tl(Locale.download, this.props.parent)}
        </button>
  }


  downloadAppReport = (event) => {
    const target = event.currentTarget.value
    authRequest(this, "S-PDD")
        .then(token => apiRequest(token, target))
        .then(resp => Promise.resolve(resp).then(x => PCO.assert(x, (x) => x.status === 200)))
        .then(res => res.blob())
        .then(zip => download(zip, `${target}.zip`, "application/zip"))
        .then(zip => {
          this.props.alert.show(tl(Locale.download, this.props.parent), { type: "success" })
        })
        .catch(err => {
          console.log(err)
          this.props.alert.show(tl(Locale.download, this.props.parent), { type: "error" })
        }
    );

  }

	render() {
		const reports = this.props.parent.state.reports.data
    const userId = this.props.parent.state.reports.userId

		return (
      <div className="app-reports-map flex-col center-h">
      <h1>{tl(Locale.app_reports, this.props.parent)}</h1>
      <button
        className="details-close flex-col flex-middle"
        onClick={this.props.parent.removeOverlay}
      >+</button>
      <div></div>
      <table className="table-logs-wrapper" id="logs-table">

        {/* Table head */}
        <thead>
          <tr>
            <th>{tl(Locale.timestamp, this.props.parent)}</th>
            <th>{tl(Locale.os, this.props.parent)}</th>
            <th>{tl(Locale.app_version, this.props.parent)}</th>
            <th>{tl(Locale.fw_version, this.props.parent)}</th>
            <th>{tl(Locale.phone_model, this.props.parent)}</th>
            <th>{tl(Locale.zip, this.props.parent)}</th>
          </tr>
        </thead>

        <tbody>
          {reports.sort((a, b) => b.timestamp - a.timestamp).map(report => <tr key={`reports-${report.timestamp}`}>
            <td>{renderDate(report.timestamp)}</td>
            <td>{report.os}</td>
            <td>{report.app_version}</td>
            <td>{report.firmware_version}</td>
            <td>{report.phone_model}</td>
            <td>{this.smallDownloadButton(userId, report.timestamp, this.downloadAppReport)}</td>
          </tr>)}
        </tbody>
    </table>
    </div>
    )
	}
}

export default withAlert(AppReports)