// Local functions
import { notice } from './errors'

// Local data
import ERR                from '../data/locale/errors.json'
import { baseURL, abort } from '../data/config'

/*
**  HTTP helpers
*/

// Authorize API requests
export function authRequest(component, tag) {
  return component.props.parent.state.user
    ? component.props.parent.state.user.getIdToken(true)
        .catch(err => notice(component, ERR.credentials, tag, true, component.props.parent.state.lang))
    : Promise.reject("Request auth aborted").catch(console.log)
}

// Compose Querystring
export function composeQS(qs) {
  return ("?" + Object.entries(qs)
      .map(([k,v]) => `${k}=${encodeURIComponent(v)}`)
      .join('&')
    ).replace(/^\?$/g, '')
}

// Make API request
export function apiRequest(token, path, params = {}, qs = {}) {
  return fetch(`${baseURL}${path}${composeQS(qs)}`, Object.assign({
    method: 'GET',
    headers: { Authorization: token },
    signal: abort.signal
  }, params))
}
