// Local functions
import { fullString } from './common'

/*
**  Locale helpers
*/

// Translate text based on key
export function tl(struct, obj) {
  const msg = struct[obj.state.lang]
  return fullString(msg) ? msg : struct.en
}
