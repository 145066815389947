import React, { Component } from 'react'
import { tl } from '../../helpers/locale'
import Locale from '../../data/locale/Admin.json'
import { authRequest, apiRequest }  from "../../helpers/http"
import { withAlert } from 'react-alert'

const PCO = require('promise-composer')


class TransferForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      userId: this.props.parent.state.transfer,
      loading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const id = this.state.userId
    // Start loading
    this.setState({ loading: true }, () => {

      // Compute request
      authRequest(this)
        .then(token => apiRequest(token, `/users/${id}/transfer`, {
          method: "POST",
          body: JSON.stringify({email: this.state.value.trim()})
        }))
        .then(resp => Promise.resolve(resp).then(x => PCO.assert(x, (x) => x.status === 204)))
        .then(resp => {
          this.setState({ loading: false })
          this.props.alert.show(tl(Locale.transferSuccess, this.props.parent), {type: "success"})
          this.props.parent.removeOverlay()
        })

        // Handle errors
        .catch(err => {
          this.setState({ loading: false })
          this.props.alert.show(tl(Locale.transferFailed, this.props.parent), {type: "error"})
        })
    })
  }

  render() {
    return (
      <div className="session-map flex-col center-h">
        <button
          className="details-close flex-col flex-middle"
          onClick={this.props.parent.removeOverlay}
        >+</button>
        <form onSubmit={this.handleSubmit}>
          <label>
            {tl(Locale.newEmail, this.props.parent)}
            <input type="text" value={this.state.value} onChange={this.handleChange} />
          </label>
          <input type="submit" value={tl(Locale.send, this.props.parent)} />
        </form>    
      </div>

    );
  }
}

export default withAlert(TransferForm)
