// Local functions
import { fullString } from './common'

/*
**  Error handling class
*/

export function handle(component, err, tag, callback = () => null, lang = component.state.lang) {
  const prefix = fullString(tag) ? tag : "?"
  component.setState({
    error: `[${prefix}] ${err[lang]}`,
    loading: false
  }, callback)
}

export function notice(component, err, tag, endLoad, lang = component.state.lang) {
  const prefix = fullString(tag) ? tag : "?"
  component.props.alert.show(`[${prefix}] ${err[lang]}`, { type: "error" })
  if (endLoad) {
    component.setState({ loading: false })
  }
}
