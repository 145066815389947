// React core
import React, { Component } from 'react'

// Local functions
import { tl } from '../../helpers/locale'

// Local data
import Locale from '../../data/locale/Header.json'

// CSS Imports
import '../../stylesheets/components/viewports/Header.css'

/*
**  App header class
*/

class Header extends Component {

  // Main render
  render() {
    return <header className="falcon-header flex-row center-v">
      <img src="/favicon.png" alt="Equisense web"/>
      <h1>Equisense Web</h1>
      {this.props.parent.state.user && this.userSpace()}
    </header>
  }

  // Render user space
  userSpace = () => {
    return <div className="userspace flex-col center-v flex-right">
      <button
        className="logout-button flex-col flex-middle"
        onClick={() => this.props.parent.logout()}
      >{tl(Locale.logout, this.props.parent)}</button>
      {this.props.parent.state.userData && <p className="welcome">
        {`${tl(Locale.welcome, this.props.parent)}, ${this.props.parent.state.userData.first_name} ${this.props.parent.state.userData.last_name}`.trim()}
      </p>}
    </div>
  }

}

export default Header
