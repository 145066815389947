// React core
import React, { Component } from 'react'

// Local components
import LoadingSpinner from '../LoadingSpinner'
import MiniLoading    from '../MiniLoading'
import RawDataSwitch from '../RawDataSwitch'

// Local functions
import { tl }                       from '../../helpers/locale'
import { exists, fullString }       from '../../helpers/common'
import { authRequest, apiRequest }  from "../../helpers/http"

// Local data
import Locale from '../../data/locale/Admin.json'
import Errors from '../../data/locale/errors.json'
import AppReports from '../overlays/AppReports'
import Transfer from '../overlays/Transfer'

// External functions
import { withAlert } from 'react-alert'

// CSS imports
import '../../stylesheets/components/viewports/Admin.css'

/*
**  Admin search window
*/

const PCO = require('promise-composer')
const download = require('downloadjs')


class Admin extends Component {

  // Class constructor
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      loading: false,
      loadingPromote: [],
      loadRawData: false,
      loadExport: false,
      // Query parameters
      query: {
        firstname: "",
        lastname:  "",
        email:      ""
      }
    }
  }

  // Update query
  updateQuery = (event) => {
    this.setState({ query: Object.assign(this.state.query, {
      [event.currentTarget.name]: event.currentTarget.value
    })})
  }

  // Render query input
  queryInput = (key) => {
    return <input
      key={`query-input-${key}`}
      type="text"
      name={key}
      value={this.state.query[key]}
      onChange={this.updateQuery}
      placeholder={tl(Locale[key], this.props.parent)}
    />
  }

  // Render users list
  renderUsers = () => {
    return <div className="table-wrapper">
      <table className="fp-table">

        {/* Table head */}
        <thead>
          <tr>
            <th>{tl(Locale.firstname, this.props.parent)}</th>
            <th>{tl(Locale.lastname, this.props.parent)}</th>
            <th>{tl(Locale.email, this.props.parent)}</th>
            <th>{tl(Locale.rawdata, this.props.parent)}</th>
            <th>{tl(Locale.app_reports, this.props.parent)}</th>
            <th></th>
            <th></th>
            <th>{tl(Locale.export_transfer, this.props.parent)}</th>
            <th>{tl(Locale.delete, this.props.parent)}</th>
          </tr>
        </thead>

        {/* Table body */}
        <tbody>
          {this.state.users.map(user => <tr key={`user-${user.id}`}>
            <td>{user.first_name}</td>
            <td>{user.last_name}</td>
            <td>{user.email}</td>
            <td>
              <RawDataSwitch
                checked={user.manual_data}
                id={user.id}
                onChange={this.switchRawData}/>
            </td>
            <td>
              <button
                className="table-button blue"
                value={user.id}
                onClick={this.getLogs}>🔍</button>
            </td>
            <td>
              <button
                className="table-button green"
                value={user.id}
                onClick={this.props.checkProfile}
              >{tl(Locale.open, this.props.parent)}</button>
            </td>
            <td>
              {this.state.loadingPromote.includes(user.id)
                ? <MiniLoading trigger={true}/>
                : <button
                    className="table-button pink"
                    value={user.id}
                    data-email={user.email}
                    onClick={this.promoteUser}
                    disabled={user.status >= 3}
                  >{user.status >= 3
                    ? (user.status >= 4
                      ? tl(Locale.demote, this.props.parent)
                      : tl(Locale.already, this.props.parent)
                    )
                    : tl(Locale.promote, this.props.parent)
                  }</button>
              }
            </td>
            <td>
              {this.state.loadExport === user.id
                ? <MiniLoading trigger={true}/>
                : <button
                    className="table-button red"
                    value={user.id}
                    onClick={this.getExportData}>⬇
                  </button>
              }
              <button
                className="table-button"
                value={user.id}
                onClick={this.transferData}>🔄
              </button>
            </td>
            <td>
              <button
                className="table-button white"
                value={user.id}
                onClick={(e) => { if (window.confirm(tl(Locale.confirmDelete, this.props.parent))) this.deleteUser(e) } }
                disabled={!exists(user.deleted_at)}>❌
              </button>
            </td>
          </tr>)}
        </tbody>
      </table>
    </div>
  }

  // Main render
  render() {

    // Determine if table should be disabled
    const disabled = Object.values(this.state.query)
      .filter(fullString)
      .length === 0

    // Return viewport
    return <div className="admin-wrapper flex-col center-h">
      <h2>{tl(Locale.admin, this.props.parent)}</h2>
      <form onSubmit={this.queryUser} className="flex-col center-h">
        {Object.keys(this.state.query).map(this.queryInput)}
        <input
          type="submit"
          className="table-button"
          disabled={disabled || this.state.loading}
          value={tl(Locale.query, this.props.parent)}
        />
      </form>
      {this.state.loading
        ? <LoadingSpinner parent={this.props.parent}/>
        : (this.state.users.length > 0
          ? this.renderUsers()
          : <p>{tl(Locale.noresults, this.props.parent)}</p>
        )
      }
    </div>
  }

  switchRawData = (event, id) => {
    this.setState({ loadRawData: true }, () => {
      authRequest(this, "RD")
        .then(token => apiRequest(token, `/users`, {
          method: "PUT",
          body: JSON.stringify({id: id, manual_data: event})
        }))
        .then(resp => Promise.resolve(resp).then(x => PCO.assert(x, (x) => x.status === 204)))
        .then(resp => {
          this.setState({ loadRawData: false })
          this.props.alert.show(tl(Locale.success, this.props.parent), {type: "success"})
        })

        // Handle errors
        .catch(err => {
          this.setState({ loadRawData: false })
          this.props.alert.show(tl(Locale.failed, this.props.parent), {type: "error"})
        })
    })
  }


  // Promote user
  promoteUser = (event) => {
    const id = event.currentTarget.value
    const email = event.currentTarget.dataset.email

    // Start loading
    this.setState({ loadingPromote: this.state.loadingPromote.concat([id]) }, () => {

      // Compute request
      authRequest(this)
        .then(token => apiRequest(token, "/researchers", {method: "POST"}, {email: email}))
        .then(() => {
          this.props.alert.show(tl(Locale.promoteSuccess, this.props.parent), { type: "success" })
          this.setState({
            loadingPromote: this.state.loadingPromote.filter(x => x !== id),
            users: this.state.users.map(user => user.id === id
              ? Object.assign(user, {status: 3})
              : user
            )
          })
        })

        // Compute error
        .catch(err => {
          console.log(err)
          this.props.alert.show(tl(Errors.unknown, this.props.parent), { type: "error" })
          this.setState({ loadingPromote: this.state.loadingPromote.filter(x => x !== id) })
        })
    })
  }

  // Query user
  queryUser = (event) => {
    event.preventDefault()
    const qs = Object.entries(this.state.query)
      .filter(([k,v]) => fullString(v))
      .reduce((acc, [k,v]) => Object.assign(acc, {[k]: v}), {})

    // Start loading
    this.setState({ loading: true }, () => {

      // Compute request
      authRequest(this)
        .then(token => apiRequest(token, `/users_search`, {}, qs))
        .then(resp => resp.json())
        .then(users => this.setState({
          users: users,
          loading: false
        }))
        .catch(() => this.props.alert.show(tl(Errors.unknown, this.props.parent), { type: "error" }))
    })
  }


  getExportData = (event) => {
    const id = event.currentTarget.value
    this.setState({ loadExport: id }, () => {
      authRequest(this)
        .then(token => apiRequest(token, `/users/${id}/export`, {}, {}))
        .then(resp => Promise.resolve(resp).then(x => PCO.assert(x, (x) => x.status === 200)))
        .then(res => res.blob())
        .then(zip => download(zip, `${id}.zip`, "application/zip"))
        .then(zip => {
          this.setState({ loadExport: false }, () => {
            this.props.alert.show(tl(Locale.download, this.props.parent), { type: "success" })
          })
        })
        .catch(err => {
          console.log(err)
          this.props.alert.show(tl(Locale.download, this.props.parent), { type: "error" })
        })
      })
  }

  getLogs = (event) => {
    const id = event.currentTarget.value
    this.setState({ loadJson: id }, () => {
    // Start loading
      authRequest(this)
        .then(token => apiRequest(token, `/users/${id}/app_reports`, {}, {}))
        .then(resp => resp.json())
        .then(data => {
          this.setState({ loadJson: false }, () => {

            // Set parent state and update Overlay
            this.props.parent.setState({ reports: {
              data: data,
              userId: id
            }}, () => { this.props.parent.showOverlay(AppReports) })
          })
        // Compute error
        })
        .catch(err => {
          console.log(err)
          this.props.alert.show(tl(Locale.failedDetails, this.props.parent), {type: "error"})
          this.setState({ loadJson: false })
        })
      })
  }

  transferData = (event) => {
    const id = event.currentTarget.value
    this.props.parent.setState({transfer: id}, () => { this.props.parent.showOverlay(Transfer)})
  }

  deleteUser = (event) => {
    const id = event.currentTarget.value
    console.log("gooooo")
    this.setState({ deleteUser: id }, () => {
      authRequest(this, "RD")
        .then(token => apiRequest(token, `/users/${id}`, {
          method: "DELETE",
        }))
        .then(resp => Promise.resolve(resp).then(x => PCO.assert(x, (x) => x.status === 204)))
        .then(resp => {
          this.setState({ deleteUser: false })
          this.props.alert.show(tl(Locale.deleteSuccess, this.props.parent), {type: "success"})
          window.location.reload(false)
        })
        // Handle errors
        .catch(err => {
          this.setState({ deleteUser: false })
          this.props.alert.show(tl(Locale.failed, this.props.parent), {type: "error"})
        })
    })
  }
}

export default withAlert(Admin)
