// React core
import React, { Component } from 'react'
import ReactDOM from 'react-dom'

// Local components
import App from './components/App'

// External components
import { Provider as AlertProvider } from 'react-alert'

// CSS Imports
import './stylesheets/base/index.css'
import './stylesheets/base/falcon.css'
import './stylesheets/base/fonts.css'

/*
**  Alert template
*/

const template = ({ message, options, style, close }) => {

  // Determine icon
  const icon = {
    "info": "?",
    "error": ":(",
    "warning": "⚠",
    "success": ":)"
  }[options.type]

  // Return rendered template
  return <div style={{...style}} className={`flex-row center-v alert-wrapper ${options.type}`}>
    <strong>{icon}</strong>
    <span>{message}</span>
    <button onClick={close} title="Dismiss">✕</button>
  </div>
}

/*
**  Root class
*/

const options = {
  position: 'bottom right',
  offset: '0 10px 10px 10px',
  timeout: 6000
}

class Root extends Component {
  render() {
    return <AlertProvider template={template} {...options}>
      <App/>
    </AlertProvider>
  }
}

// Render DOM
ReactDOM.render(<Root/>, document.getElementById('root'))
