/*
**  Common app configuration
*/

import { envType } from '../../package.json'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

const credentials = {

  // Production credentials
  "production": {
    apiKey: "AIzaSyCmaDLFmtx0hGPD6PK0V2HkMMR10yb9Elg",
    authDomain: "equisense-41c29.firebaseapp.com",
    databaseURL: "https://equisense-41c29.firebaseio.com",
    projectId: "equisense-41c29",
    storageBucket: "equisense-41c29.appspot.com",
    messagingSenderId: "397823848513"
  },

  // Load staging credentials
  "staging": {
    apiKey: "AIzaSyDIbah2mpKoJhrAGeKnFnP4vNNj76qJ18Q",
    authDomain: "equisense-staging.firebaseapp.com",
    databaseURL: "https://equisense-staging.firebaseio.com",
    storageBucket: "equisense-staging.appspot.com",
    messagingSenderId: "1047127939297"
  }
}

firebase.initializeApp(credentials[envType])

// Providers
export const auth = firebase.auth
export const database = firebase.database
export const providers = {
  "google": new auth.GoogleAuthProvider(),
  "facebook": new auth.FacebookAuthProvider(),
  "apple": new firebase.auth.OAuthProvider('apple.com')
}
providers.apple.addScope('email')
providers.apple.addScope('name')

// Base URL configurations
export const baseURL = {
  production: "https://www.equisenseapi.com",
  staging: "https://staging-api.equisense.com",
  local: "http://localhost:8080"
}[envType]

// Default webapp language code
export const languages = [ "en", "fr", "de" ]
export const defaultLang = "en"

// Pagination size
export const paginationSize = 10

// HTTP abort controller
export let abort = new AbortController()
export function refreshAbort() {
  abort = new AbortController()
}
